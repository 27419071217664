import { deletePresence, savePresence } from 'api/calendar'
import { Presence } from 'models/calendar'
import { canDeletePresence } from 'permissions'
import { Button } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import { FaCheck, FaTrash } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

const PresenceActions: React.FC<Presence> = (props) => {
    const qc = useQueryClient()
    const { isLoading, mutate } = useMutation(savePresence, {
        onSuccess: () => {
            toast.success('Obecność zapisana')
            qc.invalidateQueries([
                'calendarWork',
                `${props.stationId}`,
                props.date,
            ])
        },
        onError: (error: any) => {
            const resp = error.response
            if (resp.status === 403) toast.error(resp.data)
            else toast.error('Wystąpił błąd')
        },
    })
    const canDelete = canDeletePresence()

    const { isLoading: isDeleting, mutate: mutateDelete } = useMutation(
        deletePresence,
        {
            onSuccess: () => {
                toast.success('Obecność usunięta')
                qc.invalidateQueries([
                    'calendarWork',
                    `${props.stationId}`,
                    props.date,
                ])
            },
            onError: () => {
                toast.error('Wystąpił błąd')
            },
        }
    )

    if (props.presence === null) {
        return (
            <div className="d-flex gap-2">
                <Button
                    variant="danger"
                    className="flex-grow-1"
                    disabled={isLoading}
                    onClick={() => mutate({ ...props, presence: false })}
                >
                    <CgClose />
                </Button>
                <Button
                    variant="success"
                    className="flex-grow-1"
                    disabled={isLoading}
                    onClick={() => mutate({ ...props, presence: true })}
                >
                    <FaCheck />
                </Button>
            </div>
        )
    }

    return (
        <div className="d-flex gap-2 align-items-center justify-content-between">
            {props.presence ? (
                <div className="text-success">Obecność potwiedzona</div>
            ) : (
                <div className="text-danger">Brak obecności</div>
            )}

            {canDelete && (
                <Button
                    variant="danger"
                    disabled={isDeleting}
                    onClick={() => mutateDelete(props)}
                >
                    <FaTrash />
                </Button>
            )}
        </div>
    )
}

export default PresenceActions
