import { TemporaryGroup, TemporaryGroupPayload } from 'models/temporaryGroups'
import api from './api'

export const fetchGroups = async (userId: string) => {
    const resp = await api.get<TemporaryGroup[]>(`/temp-groups/${userId}`)

    return resp.data
}

export const createGroup = async (data: TemporaryGroupPayload) => {
    const resp = await api.post(`/temp-groups/${data.userId}`, data)

    return resp.data
}
