import React from 'react'
import { Spinner as BsSpinner } from 'react-bootstrap'

const Spinner = () => {
    return (
        <div className="d-flex h-100 justify-content-center align-items-center">
            <BsSpinner animation="grow" variant="primary" />
        </div>
    )
}

export default Spinner
