import dayjs from 'dayjs'
import { Day, SchedulesLayout } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useEffect, useRef } from 'react'
import { isToday } from 'utils'

type Props = {
    days: Day[]
    setDays: (days: Day[]) => void
}

const GridHeader: React.FC<Props> = ({ days, setDays }) => {
    const {
        state: { year, month, quarter, layout },
    } = useSchedulesContext()

    useEffect(() => {
        const start = dayjs(`${year}-${month}-01`)
        const end = start.endOf('month')

        const data: Day[] = []
        let date = start
        do {
            data.push({
                number: +date.format('D'),
                weekday: date.format('ddd'),
            })
            date = date.add(1, 'day')
        } while (date < end)

        setDays(data)
    }, [month, quarter])

    const headerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([e]) => {
                e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
            },
            { threshold: 1 }
        )

        if (headerRef.current) observer.observe(headerRef.current)

        return () => {
            if (headerRef.current) observer.unobserve(headerRef.current)
        }
    }, [headerRef])

    return (
        <>
            <div className="header col-span-2">Dzień miesiąca</div>
            <div className="header row-2 col-span-2" ref={headerRef}>
                Dzień tygodnia
            </div>

            {layout === SchedulesLayout.Stations ? (
                <>
                    <div className="header row-3 sticky-top">Posterunek</div>
                    <div className="header row-3 sticky-top">Użytkownik</div>
                </>
            ) : (
                <>
                    <div className="header row-3 sticky-top">Użytkownik</div>
                    <div className="header row-3 sticky-top">Posterunek</div>
                </>
            )}

            {days.map((d) => {
                const boundary = isToday(year, month, d.number)
                    ? 'day-boundary'
                    : ''

                return (
                    <React.Fragment key={d.number}>
                        <div
                            className={`header sticky-top row-span-n d-flex flex-column ${boundary}`}
                            style={{ '--row-span': 3 } as React.CSSProperties}
                        >
                            <span>{d.number}</span>
                            <span>{d.weekday}</span>
                        </div>
                    </React.Fragment>
                )
            })}

            <div className="header col-span-2 sticky-top">Suma</div>
            <div className="header row-span-2">Różnica</div>
            <div className="header row-span-2">Godziny</div>
        </>
    )
}

export default React.memo(GridHeader)
