import { postStationWeekdayHours } from 'api/stations'
import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Col, Row } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
    id: number
    hours: number[]
}

const StationHoursForm: React.FC<Props> = ({ id, hours }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { isLoading, mutate } = useMutation(postStationWeekdayHours)

    const formik = useFormik({
        initialValues: hours,
        onSubmit: (values) => {
            mutate(
                { id, hours: values },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries([
                            'stationWeekdayHour',
                            id,
                        ])
                        toast.success('Posterunek został zapisany.')
                        navigate(`/configuration/stations`)
                    },
                    onError: (error: any) => {
                        toast.error(
                            error.response.status === 422
                                ? 'Proszę wypełnić poprawnie wszystkie pola'
                                : 'Wystąpił błąd, prosimy spróbować ponownie'
                        )
                    },
                }
            )
        },
    })

    const setHours = (day: number, value: number) => {
        const values = formik.values
        values[day] = value
        formik.setValues(values)
    }

    const DayRow: React.FC<{ label: string; day: number }> = useCallback(
        ({ label, day }) => (
            <Form.Group as={Row} controlId="name" className="mb-3">
                <Form.Label column sm={4}>
                    {label}
                </Form.Label>
                <Col sm={8}>
                    <Form.Control
                        type="text"
                        placeholder="Wprowadź wymaganą liczbę godzin"
                        onChange={(e) => {
                            setHours(day, +e.target.value)
                        }}
                        value={formik.values[day]}
                    />
                </Col>
            </Form.Group>
        ),
        []
    )

    return (
        <Form onSubmit={formik.handleSubmit}>
            <DayRow label="Poniedziałek" day={1} />
            <DayRow label="Wtorek" day={2} />
            <DayRow label="Środa" day={3} />
            <DayRow label="Czwartek" day={4} />
            <DayRow label="Piątek" day={5} />
            <DayRow label="Sobota" day={6} />
            <DayRow label="Niedziela" day={0} />

            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default StationHoursForm
