import LocationForm from 'components/Configuration/LocationForm'
import Spinner from 'components/Spinner'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { fetchLocation, editLocation } from 'api/locations'

export const EditLocation = () => {
    const params = useParams()
    const id = parseInt(params.id!)
    const { isLoading, data } = useQuery(['location', id], () =>
        fetchLocation(id)
    )

    if (isLoading) return <Spinner />

    return (
        <LocationForm
            initialValues={{ ...data!, id }}
            mutation={editLocation}
        />
    )
}

export default EditLocation
