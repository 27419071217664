import Spinner from 'components/Spinner'
import dayjs from 'dayjs'
import { useHoursByDay } from 'hooks/schedules'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React from 'react'
import { isToday, makeArray, makeDate } from 'utils'

const GridFooter = () => {
    const {
        state: { locationId, year, month },
    } = useSchedulesContext()
    const days = dayjs(`${year}-${month}-01`).daysInMonth()
    const { isLoading, data } = useHoursByDay(locationId!, year, month)

    if (isLoading) return <Spinner />

    let total = 0

    return (
        <>
            <div className="header col-span-2">Suma godzin w dniu</div>

            {makeArray(days).map((_, i) => {
                const boundary = isToday(year, month, i + 1)
                    ? 'day-boundary'
                    : ''
                const key = makeDate(year, month, i + 1)
                const value = data![key]

                if (value) total += +value

                return (
                    <div key={i} className={`header ${boundary}`}>
                        {value ?? '-'}
                    </div>
                )
            })}

            <div className="header"></div>
            <div className="header">{total}</div>
        </>
    )
}

export default GridFooter
