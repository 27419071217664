import dayjs from 'dayjs'
import { useSchedulesContext } from 'providers/SchedulesProvider'

const PrintMonthHeader = () => {
    const { state } = useSchedulesContext()

    const day = dayjs(`${state.year}-${state.month}-01`)
    return (
        <h2 className="print text-center fw-bold">{day.format('MMMM YYYY')}</h2>
    )
}

export default PrintMonthHeader
