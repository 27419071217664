import { Errors, UserAssignment } from 'models'
import { User } from 'models/users'
import api from './api'

export const fetchUserAssignments = async (
    id: number
): Promise<UserAssignment[]> => {
    const resp = await api.get(`/locations/${id}/users`)
    const data = resp.data

    return data.map((d: any) => ({
        userId: d.user_id,
        stationId: d.station_id,
        checked: true,
    }))
}

export const fetchUserAssignmentsWithData = async (
    id: number,
    year?: number,
    month?: number
): Promise<User[]> => {
    let url = `/locations/${id}/users?withUserData=1`
    if (year && month) url += `&year=${year}&month=${month}`

    const resp = await api.get(url)
    const data = resp.data

    return data.map((d: any) => ({ ...d.user, stationId: d.station_id }))
}

export const setAssignment = async (data: UserAssignment) => {
    const url = data.stationId
        ? `/stations/${data.stationId}/users`
        : `/locations/${data.locationId}/users`
    const payload = { user_id: data.userId }

    return data.checked
        ? await api.post<Errors>(url, payload)
        : await api.delete<Errors>(url, { data: payload })
}
