import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Actions } from 'reducers/schedulesReducer'
import { GrFormNextLink, GrFormPreviousLink } from 'react-icons/gr'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import dayjs from 'dayjs'

interface Month {
    month: number
    year: number
    label: string
}

const DateBar = () => {
    const { state, dispatch } = useSchedulesContext()
    const [months, setMonths] = useState<Month[]>([])

    useEffect(() => {
        const months: Month[] = []
        let day = dayjs(new Date(state.year, state.month, 1))
            .startOf('year')
            .add(state.quarter - 1, 'quarter')

        for (let i = 0; i < 3; i++) {
            months.push({
                month: day.month() + 1,
                year: day.year(),
                label: day.format('MMMM YYYY'),
            })
            day = day.add(1, 'month')
        }

        setMonths(months)
    }, [state.quarter])

    return (
        <div className="d-flex flex-grow-1 justify-content-center justify-content-xl-start gap-3">
            <Button
                variant="link"
                onClick={() => dispatch({ type: Actions.SelectPrevQuarter })}
            >
                <GrFormPreviousLink size="1.25rem" />
            </Button>
            {months.map((sel, i) => (
                <Button
                    variant="link"
                    key={i}
                    className={`text-decoration-none ${
                        sel.month === state.month
                            ? 'text-secondary fw-bold'
                            : ''
                    }`}
                    onClick={() =>
                        dispatch({ type: Actions.SetMonth, month: sel.month })
                    }
                >
                    {sel.label}
                </Button>
            ))}
            <Button
                variant="link"
                onClick={() => dispatch({ type: Actions.SelectNextQuarter })}
            >
                <GrFormNextLink size="1.25rem" />
            </Button>
        </div>
    )
}

export default DateBar
