import dayjs from 'dayjs'

export const makeArray = (count: number) => new Array(count).fill(1)

export const makeDate = (year: any, month: any, day: any) =>
    `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`

export const isToday = (year: number, month: number, day: number) =>
    dayjs(`${year}-${month}-${day}`).isToday()

export const getDBWeekday = (date: string) => {
    const weekday = dayjs(date).isoWeekday()

    return weekday === 7 ? 0 : weekday
}

export const formatHourMinutes = (time: string) =>
    dayjs(`2000-01-01 ${time}`).format('H:mm')
