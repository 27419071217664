export enum UserGroup {
    Management = 1,
    SecurityChief,
    Commander,
    Employee,
    Hr,
}

export const groupNames = {
    [UserGroup.Management]: 'Kierownictwo',
    [UserGroup.SecurityChief]: 'Szef ochrony',
    [UserGroup.Commander]: 'Dowódca',
    [UserGroup.Employee]: 'Pracownik',
    [UserGroup.Hr]: 'HR',
}

export interface Group {
    [key: string]: UserGroup
}
