import React, { useReducer } from 'react'
import DateBar from 'components/Schedules/DateBar'
import FiltersBar from 'components/Schedules/FiltersBar'
import ShiftPicker from 'components/Schedules/ShiftPicker'
import Grid from 'components/Schedules/Grid'
import { reducer, State } from 'reducers/schedulesReducer'
import dayjs from 'dayjs'
import SchedulesContext from 'providers/SchedulesProvider'
import { Contract, SchedulesLayout } from 'models'
import Validation from 'components/Schedules/Validation'
import PrintMonthHeader from 'components/Schedules/PrintMonthHeader'

export const Schedules = () => {
    const initialState: State = {
        month: dayjs().month() + 1,
        quarter: dayjs().quarter(),
        year: dayjs().year(),
        availableContract: Contract.All,
        layout: SchedulesLayout.Stations,
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <SchedulesContext.Provider value={{ state, dispatch }}>
            <PrintMonthHeader />
            <div
                className="d-flex justify-content-between align-items-center flex-wrap gap-3"
                id="search-bar"
            >
                <DateBar />
                <FiltersBar />
                <Validation />
            </div>
            {state.locationId && state.locationId > 0 ? (
                <>
                    <ShiftPicker />
                    <Grid />
                </>
            ) : null}
        </SchedulesContext.Provider>
    )
}
