import { fetchUserAssignmentsWithData } from 'api/stationUsers'
import { fetchStations, fetchStationsWeekdayHours } from 'api/stations'
import { fetchHours, fetchWork, setWork } from 'api/work'
import { HourSummary, Station, Work } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { fetchStationShiftTimeTypeCount } from 'api/stats'
import { User } from 'models/users'

export const useStationsByLocation = (locationId: number) =>
    useQuery(['stationsByLocation', locationId], () =>
        fetchStations(locationId!)
    )

export const useUsersByLocation = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['usersByLocation', locationId, year, month], () =>
        fetchUserAssignmentsWithData(locationId, year, month)
    )

export const useWork = (locationId: number, year: number, month: number) =>
    useQuery(['work', locationId, year, month], () =>
        fetchWork({
            locationId: locationId,
            year: year,
            month: month,
        })
    )

export const useHoursByMonthStation = (
    locationId: number,
    year: number,
    month: number,
    userId?: string
) =>
    useQuery(['hoursByMonthStation', locationId, year, month], () =>
        fetchHours({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.MonthStation,
            userId,
        })
    )

export const useHoursByUserStation = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['hoursByUserStation', locationId, year, month], () =>
        fetchHours({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.UserStation,
        })
    )

export const useHoursByStation = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['hoursByStation', locationId, year, month], () =>
        fetchHours({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.Station,
        })
    )

export const useHoursByDay = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['hoursByDay', locationId, year, month], () =>
        fetchHours({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.Day,
        })
    )

export const useMonthRemaining = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['monthRemaining', locationId, year, month], () =>
        fetchHours<{
            month: { [key: string]: number }
            quarter: { [key: string]: number }
        }>({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.MonthRemaining,
        })
    )

export const useHoursByMonthUser = (
    locationId: number,
    year: number,
    month: number,
    userId?: string
) =>
    useQuery(['hoursByMonthUser', locationId, year, month], () =>
        fetchHours({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.MonthUser,
            userId,
        })
    )

export const useHoursByUser = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['hoursByUser', locationId, year, month], () =>
        fetchHours({
            locationId: locationId!,
            year,
            month,
            type: HourSummary.User,
        })
    )

export const useStationShiftTimeType = (
    locationId: number,
    year: number,
    month: number
) =>
    useQuery(['stationShiftTimeType', locationId, year, month], () =>
        fetchStationShiftTimeTypeCount({
            locationId: locationId!,
            year,
            month,
        })
    )

export const useSetWorkMutation = (
    locationId: number,
    year: number,
    month: number
) => {
    const queryClient = useQueryClient()

    return useMutation<any, any, Work>(setWork, {
        onSuccess: () => {
            const keys = [
                'work',
                'hoursByMonthStation',
                'hoursByUserStation',
                'hoursByStation',
                'hoursByDay',
                'monthRemaining',
                'monthRemaining',
                'hoursByMonthUser',
                'hoursByUser',
                'stationShiftTimeType',
            ]
            keys.forEach((key) =>
                queryClient.invalidateQueries([key, locationId, year, month])
            )
        },
        onError: (error: any) => {
            if (error.response.data.shift_id)
                toast.error('Proszę wybrać zmianę')
            else if (error.response.status == 400)
                toast.error(error.response.data.error)
            else toast.error('Wystąpił błąd')
        },
    })
}

export const useStationsWeekdayHours = () =>
    useQuery('stationsWeekdayHours', fetchStationsWeekdayHours)

export const useSchedules = () => {
    const {
        state: { locationId, year, month, userId },
    } = useSchedulesContext()

    const temp = {
        stations: useStationsByLocation(locationId!),
        users: useUsersByLocation(locationId!, year, month),
        work: useWork(locationId!, year, month),
        hoursMonthStation: useHoursByMonthStation(
            locationId!,
            year,
            month,
            userId
        ),
        hoursUserStation: useHoursByUserStation(locationId!, year, month),
        hoursStation: useHoursByStation(locationId!, year, month),
        monthRemaining: useMonthRemaining(locationId!, year, month),
        hoursMonthUsers: useHoursByMonthUser(locationId!, year, month, userId),
        hoursUser: useHoursByUser(locationId!, year, month),
        stationsWeekdayHours: useStationsWeekdayHours(),
        stationShiftTimeType: useStationShiftTimeType(locationId!, year, month),
    }

    return {
        ...temp,
        isLoading:
            temp.stations.isLoading ||
            temp.users.isLoading ||
            temp.work.isLoading ||
            temp.hoursMonthStation.isLoading ||
            temp.hoursUserStation.isLoading ||
            temp.hoursStation.isLoading ||
            temp.monthRemaining.isLoading ||
            temp.stationsWeekdayHours.isLoading ||
            temp.stationShiftTimeType.isLoading,
    }
}

export const useFilteredStations = (stations: Station[]) => {
    const {
        state: { stationId },
    } = useSchedulesContext()

    return stationId && stationId > 0
        ? stations.filter((s) => s.id === stationId)
        : stations
}

export const useFilteredUsers = (users: User[]) => {
    const {
        state: { userId },
    } = useSchedulesContext()

    return userId && userId != '' ? users.filter((u) => u.id === userId) : users
}

export const useUniqueUsers = (users: User[]) => {
    const existingUsers: string[] = []
    const filtered = users?.filter((d) => {
        if (existingUsers.includes(d.id)) return false

        existingUsers.push(d.id)
        return true
    })

    return filtered
}
