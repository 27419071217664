import React from 'react'
import { fetchStations } from 'api/stations'
import Spinner from 'components/Spinner'
import { Form } from 'react-bootstrap'
import { useQuery } from 'react-query'

type Props = {
    locationId: number
    stationId?: number
    setStationId: (locationId: number) => void
}

const StationSelect: React.FC<Props> = ({
    locationId,
    stationId,
    setStationId,
}) => {
    const { isLoading, data } = useQuery(
        ['stationsByLocation', locationId],
        () => fetchStations(locationId)
    )

    if (isLoading) return <Spinner />

    return (
        <Form.Select
            value={stationId}
            onChange={(e) => setStationId(+e.target.value)}
        >
            <option value="0">Wybierz posterunek</option>
            {data?.map((l) => (
                <option value={l.id} key={l.id}>
                    {l.name}
                </option>
            ))}
        </Form.Select>
    )
}

export default StationSelect
