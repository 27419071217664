import { finish } from "api/lists"
import { Button } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

export const confirmFinish = (id: number,year: number, month: number) => {
    toast(<PromptFinish id={id} year={year} month={month}/>, {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false
    })
}

export const PromptFinish = ({ id, year, month }: { id: number, year: number, month: number }) => {
    const qc = useQueryClient()

    const { isLoading, mutate } = useMutation(
        finish,
        {
            onSuccess() {
                toast.dismiss()
                qc.invalidateQueries(['lists'])
                toast.success('Lista została zamknięta')
            },
            onError() {
                toast.error('Wystąpił błąd')
            },
        }
    )
    return (
        <>
            <div>Potwierdzam ostateczne zakończenie wprowadzania zmian w grafikach pracy za {year}-{month} oraz akceptuję wyliczenie wynagrodzeń dla UCP.</div>
            <div className='d-flex justify-content-between mt-2'>
                <Button variant="danger" disabled={isLoading} onClick={() => toast.dismiss()}>Nie</Button>
                <Button variant="success" disabled={isLoading} onClick={() => mutate(id)}>Tak</Button>
            </div>
        </>
    )
}

export default PromptFinish