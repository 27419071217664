import {
    SalarySupplement,
    SalarySupplementPayload,
} from 'models/salarySupplements'
import api from './api'

export const fetchSupplements = async (userId: string) => {
    const resp = await api.get<SalarySupplement[]>(
        `/salary-supplements/${userId}`
    )

    return resp.data
}

export const createSupplement = async (data: SalarySupplementPayload) => {
    const resp = await api.post(`/salary-supplements/${data.userId}`, data)

    return resp.data
}

export const deleteSupplement = async (id: number) => {
    const resp = await api.delete(`/salary-supplements/${id}`)

    return resp.data
}
