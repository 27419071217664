import { Errors } from 'models'
import React from 'react'
import { Form } from 'react-bootstrap'

type Props = {
    errors?: Errors | null
    name: string
}

const FieldError: React.FC<Props> = ({ errors, name }) => {
    if (!errors || !errors[name]) return null

    return (
        <>
            {errors[name] && (
                <Form.Control.Feedback type="invalid">
                    {errors[name].join('<br/>')}
                </Form.Control.Feedback>
            )}
        </>
    )
}

export default FieldError
