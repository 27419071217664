import { ActivatePayload, Errors, Location } from 'models'
import api from './api'

export const fetchLocations = async (withStations = false, all = false) => {
    const resp = await api.get<Location[]>(`/locations`, {
        params: { withStations: +withStations, all: +all },
    })

    return resp.data
}

export const fetchLocation = async (id: number) => {
    const resp = await api.get<Location>(`/locations/${id}`)

    return resp.data
}

export const addLocation = async (data: Location) => {
    return await api.post<Errors>(`/locations`, data)
}

export const editLocation = async (data: Location) => {
    return await api.patch<Errors>(`/locations/${data.id}`, data)
}

export const activation = async (data: ActivatePayload) => {
    return await api.patch(`/locations/${data.id}`, {
        is_active: data.is_active,
    })
}
