import { fetchLists } from 'api/lists'
import { confirmClose } from 'components/Lists/PromptClose'
import { confirmFinish } from 'components/Lists/PromptFinish'
import Spinner from 'components/Spinner'
import { isManagement } from 'permissions'
import React from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap'
import { useQuery } from 'react-query'

export const Lists = () => {
    const management = isManagement()
    const { isLoading, data } = useQuery(['lists'], fetchLists)

    if (isLoading) return <Spinner />

    return (
        <Table>
            <thead>
                <tr>
                    <th>Rok</th>
                    <th>Miesiąc</th>
                    <th>Data zamknięcia</th>
                    <th>Użytkownik zamykający</th>
                    <th>Data zakończenia</th>
                    <th>Użytkownik kończący</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data?.map((list) => (
                    <tr key={list.id}>
                        <td>{list.year}</td>
                        <td>{list.month}</td>
                        <td>{list.closed_at ?? '-'}</td>
                        <td>
                            {list.closing_user
                                ? `${list.closing_user.firstName} ${list.closing_user.lastName}`
                                : '-'}
                        </td>
                        <td>{list.final_closed_at ?? '-'}</td>
                        <td>
                            {list.final_closing_user
                                ? `${list.final_closing_user.firstName} ${list.final_closing_user.lastName}`
                                : '-'}
                        </td>
                        <td>
                            {management && !list.closed_at && (
                                <Button
                                    variant="warning"
                                    size="sm"
                                    onClick={() => confirmClose(list.id)}
                                >
                                    Potwierdzenie zamknięcia
                                </Button>
                            )}

                            {management &&
                                list.closed_at &&
                                !list.final_closed_at && (
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() =>
                                            confirmFinish(
                                                list.id,
                                                list.year,
                                                list.month
                                            )
                                        }
                                    >
                                        Ostateczne zamknięcie
                                    </Button>
                                )}

                            {management && list.final_closed_at && (
                                <Dropdown>
                                    <Dropdown.Toggle size="sm">
                                        Pobierz
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">
                                            Pobierz dane do UCP
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            Pobierz dane dodatków UCP
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            Pobierz Grafik UOP
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-4">
                                            Pobierz plik nadgodzin UOP
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default Lists
