import React from 'react'

type Props = {
    recordId: number | string
    hours: { [key: string]: number }
}

const StationHours: React.FC<Props> = ({ recordId, hours }) => {
    return (
        <div className="cell grid-center bg-gray-500 fw-bolder text-center">
            {hours[recordId] ?? '-'}
        </div>
    )
}

export default StationHours
