import { Errors } from 'models'
import {
    FetchWorkPayload,
    LocationWithStations,
    Presence,
    Work,
} from 'models/calendar'
import api from './api'

export const fetchStations = async () => {
    const resp = await api.get<LocationWithStations[]>(`/calendar/stations`)

    return resp.data
}

export const fetchWork = async (payload: FetchWorkPayload) => {
    const resp = await api.get<Work[]>(
        `/calendar/work/${payload.stationId}/${payload.date}`
    )

    return resp.data
}

export const savePresence = async (data: Presence) => {
    return await api.post<Errors>(`/calendar/presence`, {
        date: data.date,
        presence: data.presence,
        shift_id: data.shiftId,
        station_id: data.stationId,
        user_id: data.userId,
    })
}

export const deletePresence = async (data: Presence) => {
    return await api.delete<Errors>(`/calendar/presence`, {
        data: {
            date: data.date,
            shift_id: data.shiftId,
            station_id: data.stationId,
            user_id: data.userId,
        },
    })
}
