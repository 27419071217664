import { User, UserPayload } from 'models/users'
import api from './api'

export const fetchUsers = async (): Promise<User[]> => {
    const resp = await api.get(`/users`)

    return resp.data
}

export const fetchUser = async (id: string): Promise<User> => {
    const resp = await api.get(`/users/${id}`)

    return resp.data
}

export const updateUser = async ({ id, groupId }: UserPayload) => {
    return await api.patch(`/users/${id}`, { groupId })
}
