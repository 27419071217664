import { useFormik } from 'formik'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FieldError from 'components/FieldError'
import {
    SalarySupplement,
    SalarySupplementPayload,
} from 'models/salarySupplements'
import dayjs from 'dayjs'
import { createSupplement } from 'api/salarySupplements'

const today = dayjs()
const initialValues: SalarySupplement = {
    year: today.year(),
    month: today.month() + 1,
    cyclic: false,
    amount: '',
    description: '',
}

const AddForm: React.FC = () => {
    const id = useParams().id!
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { isLoading, mutate, error } = useMutation<
        any,
        any,
        SalarySupplementPayload
    >(createSupplement)
    const errors = error?.response.data
    const { values, setFieldValue, handleChange, handleSubmit } =
        useFormik<SalarySupplement>({
            initialValues,
            onSubmit: (values) => {
                mutate(
                    { ...values, userId: id },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries([
                                'salary-supplements',
                                id,
                            ])
                            toast.success('Dodatek został zapisany.')
                            navigate(-1)
                        },
                        onError: (error) => {
                            const resp = error.response

                            if (resp.status === 400)
                                toast.error(resp.data.message)
                            else toast.error('Wystąpił błąd')
                        },
                    }
                )
            },
        })

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="year" className="mb-3">
                <Form.Label>Rok</Form.Label>
                <Form.Control
                    type="number"
                    onChange={handleChange}
                    value={values.year}
                    isInvalid={!!errors?.year}
                    step="1"
                />
                <FieldError errors={errors} name="year" />
            </Form.Group>
            <Form.Group controlId="month" className="mb-3">
                <Form.Label>Miesiąc</Form.Label>
                <Form.Control
                    type="number"
                    onChange={handleChange}
                    value={values.month}
                    isInvalid={!!errors?.month}
                    step="1"
                />
                <FieldError errors={errors} name="month" />
            </Form.Group>
            <Form.Group controlId="amount" className="mb-3">
                <Form.Label>Kwota</Form.Label>
                <Form.Control
                    type="number"
                    onChange={handleChange}
                    value={values.amount}
                    isInvalid={!!errors?.amount}
                    step="0.01"
                />
                <FieldError errors={errors} name="amount" />
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
                <Form.Label>Opis</Form.Label>
                <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    value={values.description}
                    isInvalid={!!errors?.description}
                    rows={3}
                />
                <FieldError errors={errors} name="description" />
            </Form.Group>
            <Form.Check
                type="checkbox"
                name="cyclic"
                id="cyclic"
                onChange={(e) => setFieldValue('cyclic', e.target.checked)}
                label="Cykliczny"
                className="mb-3"
                checked={values.cyclic}
            />

            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
            <Button
                variant="dark"
                onClick={() => navigate(-1)}
                className="ms-2"
            >
                Wstecz
            </Button>
        </Form>
    )
}

export default AddForm
