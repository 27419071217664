import { fetchStations, fetchWork } from 'api/calendar'
import Spinner from 'components/Spinner'
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import Work from 'components/Calendar/Work'

export const Calendar = () => {
    const [stationId, setStationId] = useState('')
    const [date, setDate] = useState(dayjs())
    const { isLoading, data } = useQuery('calendarStations', fetchStations)
    const { isLoading: ilWork, data: work } = useQuery(
        ['calendarWork', stationId, date.format('YYYY-MM-DD')],
        () =>
            fetchWork({
                stationId: +stationId,
                date: date.format('YYYY-MM-DD'),
            }),
        { enabled: !!stationId && !!date }
    )

    const changeDate = (days: number) => {
        setDate(date.add(days, 'day'))
    }

    if (isLoading || ilWork) return <Spinner />

    return (
        <Row className="justify-content-center">
            <Col md={6}>
                <div className="d-flex gap-2 mb-2 sticky-top">
                    <Form.Select
                        onChange={(e) => setStationId(e.target.value)}
                        value={stationId}
                    >
                        <option value="">wybierz posterunek</option>
                        {data!.map((l) => (
                            <React.Fragment key={l.name}>
                                <optgroup
                                    label={`${l.customer_name} ${l.name}, ${l.address} ${l.post_code} ${l.city}`}
                                >
                                    {l.stations.map((s) => (
                                        <option value={s.id} key={s.id}>
                                            {s.name}
                                        </option>
                                    ))}
                                </optgroup>
                            </React.Fragment>
                        ))}
                    </Form.Select>
                    <Button onClick={() => changeDate(-1)}>&lt;</Button>
                    <Button onClick={() => changeDate(1)}>&gt;</Button>
                </div>

                {work && work.length ? (
                    <ListGroup variant="flush">
                        {work.map((w) => (
                            <ListGroup.Item key={w.id} className="px-0">
                                <Work work={w} />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : (
                    <div className="text-center text-secondary">
                        Brak zmian na {date.format('YYYY-MM-DD')}
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default Calendar
