import AddForm from 'components/Configuration/TemporaryGroups/AddForm'
import List from 'components/Configuration/TemporaryGroups/List'
import { Col, Row } from 'react-bootstrap'

export const TemporaryGroups: React.FC = () => {
    return (
        <Row>
            <Col md={6}>
                <List />
            </Col>
            <Col md={6}>
                <AddForm />
            </Col>
        </Row>
    )
}

export default TemporaryGroups
