import { ActivatePayload } from 'models'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FaPause, FaPlay } from 'react-icons/fa'
import Icon from './Icon'

interface Props {
    id: number
    isActive: boolean
    mutate: (data: ActivatePayload) => void
}

const Activation: React.FC<Props> = ({ id, isActive, mutate }) => {
    return (
        <>
            {isActive ? (
                <Button
                    variant="link"
                    onClick={() =>
                        mutate({
                            id,
                            is_active: false,
                        })
                    }
                >
                    <Icon name={FaPlay} />
                </Button>
            ) : (
                <Button
                    variant="link"
                    onClick={() =>
                        mutate({
                            id,
                            is_active: true,
                        })
                    }
                >
                    <Icon name={FaPause} />
                </Button>
            )}
        </>
    )
}

export default Activation
