import Spinner from 'components/Spinner'
import dayjs from 'dayjs'
import {
    useFilteredStations,
    useFilteredUsers,
    useSchedules,
    useUniqueUsers,
} from 'hooks/schedules'
import { Contract } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useEffect } from 'react'
import { makeArray } from 'utils'
import MonthRemaining from './MonthRemaining'
import MonthStationHours from './MonthStationHours'
import StationHours from './StationHours'
import UserHours from './UserHours'
import WorkCell from './WorkCell'

const GridBodyUsers = () => {
    const {
        state: {
            year,
            month,
            userId,
            shiftId,
            availableStations,
            availableContract,
        },
    } = useSchedulesContext()

    const days = dayjs(`${year}-${month}-01`).daysInMonth()

    const {
        hoursMonthUsers,
        hoursUser,
        hoursUserStation,
        monthRemaining,
        stations,
        users,
        work,
        isLoading,
    } = useSchedules()

    useEffect(() => {
        hoursMonthUsers.refetch()
    }, [userId])

    const filteredStations = useFilteredStations(stations.data!)
    const filteredUsers = useFilteredUsers(users.data!)
    const uniqueUsers = useUniqueUsers(filteredUsers)

    if (isLoading) return <Spinner />

    return (
        <>
            {uniqueUsers?.map((u) => {
                const disabledUser =
                    availableContract !== Contract.All &&
                    availableContract !== u.contract

                return (
                    <React.Fragment key={u.id}>
                        <div
                            className={`col-1 row-span-n cell station-name flex-column text-center ${
                                disabledUser ? 'disabled' : ''
                            }`}
                            style={{
                                ['--row-span' as string]:
                                    filteredStations?.length,
                            }}
                        >
                            {u.lastName} {u.firstName}
                            <small className="fw-light">
                                {u.number} / {u.contract}
                            </small>
                        </div>

                        {filteredStations?.map((s) => {
                            const disabled =
                                (!!shiftId &&
                                    !!availableStations &&
                                    !availableStations.includes(s.id!)) ||
                                !filteredUsers.some(
                                    (fu) =>
                                        fu.id === u.id && fu.stationId === s.id
                                )

                            return (
                                <React.Fragment key={s.id}>
                                    <div
                                        className={`text-nowrap cell grid-center sticky-left ${
                                            disabled || disabledUser
                                                ? 'disabled'
                                                : ''
                                        }`}
                                    >
                                        {s.name}
                                    </div>

                                    {makeArray(days).map((_, i) => (
                                        <WorkCell
                                            day={i + 1}
                                            stationId={s.id!}
                                            userId={u.id!}
                                            disabled={disabled || disabledUser}
                                            work={work.data!}
                                            key={i}
                                        />
                                    ))}

                                    <MonthRemaining
                                        remaining={monthRemaining.data!}
                                        userId={u.id!}
                                    />

                                    <UserHours
                                        userId={u.id}
                                        stationId={s.id!}
                                        hours={hoursUserStation.data!}
                                    />
                                </React.Fragment>
                            )
                        })}

                        <MonthStationHours
                            days={days}
                            recordId={u.id!}
                            hours={hoursMonthUsers.data!}
                            title="Suma godzin"
                        />

                        <div className="cell bg-gray-500"></div>

                        <StationHours
                            hours={hoursUser.data!}
                            recordId={u.id!}
                        />
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default GridBodyUsers
