import { ShiftTimeType } from 'models'
import { StationShiftTimeTypeCount } from 'models/stats'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React from 'react'
import { Badge } from 'react-bootstrap'
import { getDBWeekday, isToday, makeArray, makeDate } from 'utils'

type Props = {
    days: number
    recordId: number | string
    hours: { [key: string]: number }
    requiredHours?: number[] | undefined
    timeTypeShifts?: StationShiftTimeTypeCount[]
    title: string
}

const MonthStationHours: React.FC<Props> = ({
    days,
    recordId,
    hours,
    requiredHours,
    timeTypeShifts,
    title,
}) => {
    const {
        state: { year, month },
    } = useSchedulesContext()

    return (
        <>
            <div className="col-span-2 cell bg-gray-500 fw-bolder text-center">
                {title}
            </div>
            {makeArray(days).map((_, i) => {
                const date = makeDate(year, month, i + 1)
                const key = `${date}:${recordId}`
                const boundary = isToday(year, month, i + 1)
                    ? 'day-boundary'
                    : ''
                const weekday = getDBWeekday(date)
                const shiftTypesDay = timeTypeShifts?.find(
                    (t) =>
                        t.date === date &&
                        t.station_id == recordId &&
                        t.time_type === ShiftTimeType.Day
                )
                const shiftTypesNight = timeTypeShifts?.find(
                    (t) =>
                        t.date === date &&
                        t.station_id == recordId &&
                        t.time_type === ShiftTimeType.Night
                )

                return (
                    <div
                        className={`cell bg-gray-500 text-center ${boundary}`}
                        key={i}
                    >
                        <div className="fw-bolder">
                            {hours[key] ?? '-'}/
                            <span title="Liczba godzin zaplanowanych w stosunku do wymagań posterunku">
                                {hours[key] && requiredHours?.[weekday]
                                    ? requiredHours?.[weekday] - hours[key]
                                    : '-'}
                            </span>
                        </div>
                        {timeTypeShifts && (
                            <div className="d-flex gap-1 justify-content-center">
                                <Badge
                                    bg="primary"
                                    title="Liczba zmian dziennych"
                                >
                                    {shiftTypesDay ? shiftTypesDay.cnt : '-'}
                                </Badge>
                                <Badge bg="dark" title="Liczba zmian nocnych">
                                    {shiftTypesNight
                                        ? shiftTypesNight.cnt
                                        : '-'}
                                </Badge>
                            </div>
                        )}
                    </div>
                )
            })}
        </>
    )
}

export default MonthStationHours
