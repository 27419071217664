import { fetchShifts } from 'api/shifts'
import Spinner from 'components/Spinner'
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { useQuery } from 'react-query'
import StationShiftRow from 'components/Configuration/StationShiftRow'
import LocationSelect from 'components/LocationSelect'

export const StationShift = () => {
    const [locationId, setLocationId] = useState<number>()
    const { isLoading, data } = useQuery('shifts', fetchShifts)

    if (isLoading) return <Spinner />

    return (
        <>
            <LocationSelect
                locationId={locationId}
                setLocationId={setLocationId}
            />
            {locationId && (
                <Table hover className="mt-4">
                    <thead className="bg-white sticky-top">
                        <tr>
                            <th>&nbsp;</th>

                            {data?.map((s) => (
                                <th
                                    key={s.id}
                                    className="text-nowrap text-center"
                                >
                                    {s.name}
                                    <br />
                                    <small className="fw-light">
                                        {s.start} - {s.end}
                                    </small>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <StationShiftRow
                            locationId={locationId}
                            shifts={data!}
                        />
                    </tbody>
                </Table>
            )}
        </>
    )
}

export default StationShift
