import React from 'react'
import { fetchLocations } from 'api/locations'
import Spinner from 'components/Spinner'
import { Form } from 'react-bootstrap'
import { useQuery } from 'react-query'

type Props = {
    locationId?: number
    setLocationId: (locationId: number) => void
}

const LocationSelect: React.FC<Props> = ({ locationId, setLocationId }) => {
    const { isLoading, data } = useQuery('locations', () => fetchLocations())

    if (isLoading) return <Spinner />

    return (
        <Form.Select
            value={locationId}
            onChange={(e) => setLocationId(+e.target.value)}
        >
            <option value="0">Wybierz obiekt</option>
            {data?.map((l) => (
                <option value={l.id} key={l.id}>
                    {l.name}
                </option>
            ))}
        </Form.Select>
    )
}

export default LocationSelect
