import StationForm from 'components/Configuration/StationForm'
import Spinner from 'components/Spinner'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
    editStation,
    fetchStation,
    fetchStationWeekdayHours,
} from 'api/stations'
import { Col, Row } from 'react-bootstrap'
import StationHoursForm from 'components/Configuration/StationHoursForm'

export const EditStation = () => {
    const params = useParams()
    const id = parseInt(params.id!)
    const { isLoading: ld1, data: station } = useQuery(['station', id], () =>
        fetchStation(id)
    )
    const { isLoading: ld2, data: weekdayHours } = useQuery(
        ['stationWeekdayHour', id],
        () => fetchStationWeekdayHours(id)
    )

    if (ld1 || ld2) return <Spinner />

    return (
        <Row>
            <Col>
                <StationForm initialValues={station!} mutation={editStation} />
            </Col>
            <Col>
                <StationHoursForm id={id} hours={weekdayHours!} />
            </Col>
        </Row>
    )
}

export default EditStation
