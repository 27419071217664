import {
    Errors,
    HoursFetchParams,
    HourSummary,
    Work,
    WorkCache,
    WorkFetchParams,
} from 'models'
import api from './api'

// return object DATE:STATION_ID:USER_ID => Shift
export const fetchWork = async ({
    locationId,
    year,
    month,
}: WorkFetchParams) => {
    const resp = await api.get(
        `/locations/${locationId}/work/y-${year},m-${month}`
    )

    const data: WorkCache = {}
    resp.data.forEach((w: Work) => {
        if (!data[`${w.date}:${w.station_id}:${w.user_id}`])
            data[`${w.date}:${w.station_id}:${w.user_id}`] = []
        data[`${w.date}:${w.station_id}:${w.user_id}`].push({
            ...w.shift!,
            presence: w.presence,
        })
    })

    return data
}

export const setWork = async (data: Work) => {
    const url = `/stations/${data.station_id}/work`
    const payload = {
        date: data.date,
        shift_id: data.shift_id,
        user_id: data.user_id,
    }

    return await api.post<Errors>(url, payload)
}

export const fetchHours = async <T = Record<string, any>>({
    locationId,
    year,
    month,
    type,
    userId,
}: HoursFetchParams): Promise<T> => {
    let url = `/locations/${locationId}/hours/${type}/y-${year},m-${month}`
    if (userId) url += `?userId=${userId}`

    const resp = await api.get(url)
    const data: any = {}

    if (type === HourSummary.MonthStation) {
        resp.data.forEach((h: any) => {
            data[`${h.date}:${h.station_id}`] = h.hours
        })
    } else if (type === HourSummary.UserStation) {
        resp.data.forEach((h: any) => {
            data[`${h.user_id}:${h.station_id}`] = h.hours
        })
    } else if (type === HourSummary.Station) {
        resp.data.forEach((h: any) => {
            data[h.station_id] = h.hours
        })
    } else if (type === HourSummary.Day) {
        resp.data.forEach((h: any) => {
            data[h.date] = h.hours
        })
    } else if (type === HourSummary.MonthRemaining) {
        data.month = {}
        data.quarter = {}
        resp.data.month.forEach((h: any) => {
            data.month[h.user_id] = h.remaining
        })
        resp.data.quarter.forEach((h: any) => {
            data.quarter[h.user_id] = h.remaining
        })
    } else if (type === HourSummary.MonthUser) {
        resp.data.forEach((h: any) => {
            data[`${h.date}:${h.user_id}`] = h.hours
        })
    } else if (type === HourSummary.User) {
        resp.data.forEach((h: any) => {
            data[h.user_id] = h.hours
        })
    }

    return data
}
