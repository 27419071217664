import { UserGroup } from 'models/groups'
import { AuthContext } from 'providers/AuthProvider'
import { useContext } from 'react'

export const canAccessConfiguration = () => {
    const { profile } = useContext(AuthContext)

    return (
        profile?.groupId === UserGroup.Management ||
        profile?.groupId === UserGroup.Hr
    )
}

export const canAccessLists = canAccessConfiguration

export const canConfirmPresence = () => {
    const { profile } = useContext(AuthContext)

    return (
        profile?.groupId !== UserGroup.Employee &&
        profile?.groupId !== UserGroup.Hr
    )
}

export const canAccessSchedules = canConfirmPresence

export const canDeletePresence = () => {
    const { profile } = useContext(AuthContext)

    return profile?.groupId === UserGroup.Management
}

export const isManagement = () => {
    const { profile } = useContext(AuthContext)

    return profile?.groupId === UserGroup.Management
}
