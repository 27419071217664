import { fetchGroups } from 'api/temporaryGroups'
import Spinner from 'components/Spinner'
import dayjs from 'dayjs'
import { groupNames } from 'models/groups'
import React from 'react'
import { Table } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const List = () => {
    const id = useParams().id!
    const { isLoading, data } = useQuery(['temp-groups', id], () =>
        fetchGroups(id)
    )

    if (isLoading) return <Spinner />
    if (!data?.length) return <div>Brak uprawnień tymczasowych</div>

    return (
        <Table>
            <thead>
                <tr>
                    <th>Data</th>
                    <th>Uprawnienia</th>
                    <th>Data dodania</th>
                    <th>Nadający</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((g) => (
                    <tr key={g.id!}>
                        <td>{g.date}</td>
                        <td>{groupNames[g.groupId!]}</td>
                        <td>
                            {dayjs(g.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </td>
                        <td>{g.creatingUserId}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default List
