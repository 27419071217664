import { AxiosResponse } from 'axios'
import FieldError from 'components/FieldError'
import { useFormik } from 'formik'
import { Errors, Location } from 'models'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
    initialValues: Location
    mutation: (data: Location) => Promise<AxiosResponse<Errors, any>>
}

const LocationForm: React.FC<Props> = ({ initialValues, mutation }) => {
    const { isLoading, mutate, error } = useMutation<any, any, Location>(
        mutation
    )
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const errors = error?.response.data

    const formik = useFormik<Location>({
        initialValues,
        onSubmit: (values) => {
            mutate(values, {
                onSuccess: ({ data }: any) => {
                    queryClient.setQueryData(['location', data.id], data)
                    toast.success('Obiekt został zapisany.')
                    navigate('/configuration/locations')
                },
            })
        },
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Nazwa</Form.Label>
                <Form.Control
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!errors?.name}
                />
                <FieldError errors={errors} name="name" />
            </Form.Group>
            <Form.Group controlId="customer_name" className="mb-3">
                <Form.Label>Nazwa klienta</Form.Label>
                <Form.Control
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.customer_name}
                    isInvalid={!!errors?.customer_name}
                />
                <FieldError errors={errors} name="customer_name" />
            </Form.Group>
            <Form.Group controlId="city" className="mb-3">
                <Form.Label>Miejscowość</Form.Label>
                <Form.Control
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    isInvalid={!!errors?.city}
                />
                <FieldError errors={errors} name="city" />
            </Form.Group>
            <Form.Group controlId="post_code" className="mb-3">
                <Form.Label>Kod pocztowy</Form.Label>
                <Form.Control
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.post_code}
                    isInvalid={!!errors?.post_code}
                />
                <FieldError errors={errors} name="post_code" />
            </Form.Group>
            <Form.Group controlId="address" className="mb-3">
                <Form.Label>Adres</Form.Label>
                <Form.Control
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    isInvalid={!!errors?.address}
                />
                <FieldError errors={errors} name="address" />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default LocationForm
