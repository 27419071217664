import { setAssignment } from 'api/stationShifts'
import { ShiftAssignment, Station } from 'models'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

type Props = {
    station: Station
    shiftId: number
}

const StationShiftSwitch: React.FC<Props> = ({ station, shiftId }) => {
    const queryClient = useQueryClient()

    const { isLoading: isMutating, mutate } = useMutation<
        any,
        any,
        ShiftAssignment
    >(setAssignment, {
        onSuccess: () => {
            queryClient.invalidateQueries([
                'stationsByLocationWithShifts',
                station.location_id,
            ])
        },
        onError: () => {
            toast.error('Wystąpił błąd')
        },
    })

    const onChange = (checked: boolean) => {
        mutate({ stationId: station.id!, shiftId, checked })
    }

    return (
        <Form.Check
            type="switch"
            onChange={(e) => onChange(e.target.checked)}
            disabled={isMutating}
            checked={station.shifts!.some((sh) => sh.id === shiftId)}
        />
    )
}

export default StationShiftSwitch
