import {
    ActivatePayload,
    Errors,
    Station,
    StationPayload,
    StationWeekdayHour,
} from 'models'
import api from './api'

export const fetchStations = async (locationId: number, withShifts = false) => {
    const resp = await api.get<Station[]>(`/locations/${locationId}/stations`, {
        params: { withShifts: +withShifts },
    })

    return resp.data
}

export const fetchStation = async (id: number) => {
    const resp = await api.get<Station>(`/stations/${id}`)

    return resp.data
}

export const addStation = async (data: StationPayload) => {
    return await api.post<Errors>(
        `/locations/${data.locationId}/stations`,
        data.station
    )
}

export const editStation = async (data: StationPayload) => {
    return await api.patch<Errors>(`/stations/${data.station.id}`, data.station)
}

export const activation = async (data: ActivatePayload) => {
    return await api.patch(`/stations/${data.id}`, {
        is_active: data.is_active,
    })
}

export const fetchStationWeekdayHours = async (id: number) => {
    const resp = await api.get<StationWeekdayHour[]>(
        `/stations/${id}/weekday-hours`
    )

    return resp.data.map((h) => h.hours)
}

export const postStationWeekdayHours = async ({
    id,
    hours,
}: {
    id: number
    hours: number[]
}) => {
    return await api.post<Errors>(`/stations/${id}/weekday-hours`, { hours })
}

export const fetchStationsWeekdayHours = async () =>
    (await api.get(`/stations-weekday-hours`)).data
