import { fetchGroups } from 'api/groups'
import { fetchUsers } from 'api/users'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import { groupNames } from 'models/groups'
import { useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { FaRegEdit } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import { FiClock } from 'react-icons/fi'

export const Users = () => {
    const [value, setValue] = useState('')
    const search = useDebounce<string>(value, 500)
    const { isLoading, data } = useQuery('users', fetchUsers)
    const { isLoading: ilGroups, data: groups } = useQuery(
        'groups',
        fetchGroups
    )

    if (isLoading || ilGroups) return <Spinner />

    const users = search.length
        ? data?.filter(
            (d) =>
                `${d.firstName} ${d.lastName}`
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                (groupNames[groups![d.id]] ?? '')
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
        )
        : data

    return (
        <>
            <Form.Control
                type="search"
                placeholder="Wyszukaj"
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <Table className="mt-2">
                <thead className="bg-white sticky-top">
                    <tr>
                        <th>Użytkownik</th>
                        <th>Kod</th>
                        <th>Uprawnienia</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((u, i) => (
                        <tr key={`${i} ${u.id}`}>
                            <td>
                                {u.lastName} {u.firstName}
                            </td>
                            <td>
                                {u.number} / {u.contract}
                            </td>
                            <td>
                                {groups![u.id]
                                    ? groupNames[groups![u.id]]
                                    : '-'}
                            </td>
                            <td>
                                <Link to={`/configuration/users/${u.id}`}>
                                    <Icon name={FaRegEdit} />
                                </Link>{' '}
                                <Link
                                    to={`/configuration/users/temp-groups/${u.id}`}
                                >
                                    <Icon name={FiClock} />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default Users
