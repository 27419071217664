import { fetchStations } from 'api/stations'
import Spinner from 'components/Spinner'
import { Shift } from 'models'
import React from 'react'
import { useQuery } from 'react-query'
import StationShiftSwitch from './StationShiftSwitch'

type Props = {
    locationId: number
    shifts: Shift[]
}

const StationShiftRow: React.FC<Props> = ({ locationId, shifts }) => {
    const { isLoading, data } = useQuery(
        ['stationsByLocationWithShifts', locationId],
        () => fetchStations(locationId, true)
    )

    if (isLoading)
        return (
            <tr>
                <td colSpan={shifts.length + 1}>
                    <Spinner />
                </td>
            </tr>
        )

    return (
        <>
            {data?.map((st) => (
                <tr key={st.id}>
                    <th className="bg-white sticky-left">{st.name}</th>

                    {shifts?.map((sh) => (
                        <td key={sh.id} className="text-center">
                            <StationShiftSwitch station={st} shiftId={sh.id!} />
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
}

export default StationShiftRow
