import { fetchLocations } from 'api/locations'
import Spinner from 'components/Spinner'
import StationsList from 'components/Configuration/StationsList'
import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { IoAddCircleOutline } from 'react-icons/io5'

export const Stations = () => {
    const { isLoading, data } = useQuery('locationsWithStations', () =>
        fetchLocations(true)
    )

    if (isLoading) return <Spinner />

    return (
        <Row>
            {data?.map((l) => (
                <Col sm={12} md={6} lg={4} xl={3} className="mb-4" key={l.id}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="d-flex">
                                <div
                                    className={`d-flex justify-content-between align-items-center w-100 me-2 ${
                                        l.is_active ? '' : 'text-muted'
                                    }`}
                                >
                                    <div>
                                        {l.name}
                                        <br />
                                        <small className="fw-light">
                                            {l.customer_name}
                                        </small>
                                    </div>
                                    <Link
                                        to={`/configuration/stations/add/${l.id}`}
                                    >
                                        <IoAddCircleOutline size="1.75rem" />
                                    </Link>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                                <StationsList stations={l.stations!} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            ))}
        </Row>
    )
}

export default Stations
