import LocationSelect from 'components/LocationSelect'
import StationSelect from 'components/StationSelect'
import UserSelect from 'components/UserSelect'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Actions } from 'reducers/schedulesReducer'
import LayoutBar from './LayoutBar'

const FiltersBar = () => {
    const { state, dispatch } = useSchedulesContext()

    return (
        <div className="d-flex flex-wrap flex-lg-nowrap flex-grow-1 flex-xl-grow-0 gap-3">
            <LocationSelect
                locationId={state.locationId}
                setLocationId={(locationId?: number) =>
                    dispatch({ type: Actions.SetLocation, locationId })
                }
            />
            {state.locationId ? (
                <StationSelect
                    locationId={state.locationId}
                    stationId={state.stationId}
                    setStationId={(stationId?: number) =>
                        dispatch({ type: Actions.SetStation, stationId })
                    }
                />
            ) : (
                <Form.Select disabled>
                    <option>Wybierz posterunek</option>
                </Form.Select>
            )}
            {state.locationId ? (
                <UserSelect
                    locationId={state.locationId}
                    userId={state.userId}
                    setUserId={(userId?: string) =>
                        dispatch({ type: Actions.SetUser, userId })
                    }
                />
            ) : (
                <Form.Select disabled>
                    <option>Wybierz pracownika</option>
                </Form.Select>
            )}

            <LayoutBar />
        </div>
    )
}

export default FiltersBar
