import React from 'react'
import { fetchUserAssignmentsWithData } from 'api/stationUsers'
import Spinner from 'components/Spinner'
import { Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useUniqueUsers } from 'hooks/schedules'

type Props = {
    locationId: number
    userId?: string
    setUserId: (userId: string) => void
}

const UserSelect: React.FC<Props> = ({ locationId, userId, setUserId }) => {
    const { isLoading, data } = useQuery(['usersByLocation', locationId], () =>
        fetchUserAssignmentsWithData(locationId)
    )

    if (isLoading) return <Spinner />

    const filtered = useUniqueUsers(data!)

    return (
        <Form.Select value={userId} onChange={(e) => setUserId(e.target.value)}>
            <option value="">Wybierz użytkownika</option>
            {filtered?.map((u) => (
                <option value={u.id} key={u.id}>
                    {u.lastName} {u.firstName}
                </option>
            ))}
        </Form.Select>
    )
}

export default UserSelect
