import { editShift, fetchShift } from 'api/shifts'
import ShiftForm from 'components/Configuration/ShiftForm'
import Spinner from 'components/Spinner'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

export const EditShift = () => {
    const params = useParams()
    const id = parseInt(params.id!)
    const { isLoading, data } = useQuery(['shift', id], () => fetchShift(id))

    if (isLoading) return <Spinner />

    return <ShiftForm initialValues={{ ...data!, id }} mutation={editShift} />
}

export default EditShift
