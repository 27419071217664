import { createContext, Dispatch, useContext } from 'react'
import { Action, State } from 'reducers/schedulesReducer'

interface Props {
    state: State
    dispatch: Dispatch<Action>
}

const SchedulesContext = createContext({} as Props)

export const useSchedulesContext = () => useContext(SchedulesContext)

export default SchedulesContext
