import { close } from "api/lists"
import { Button } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

export const confirmClose = (id: number) => {
    toast(<PromptClose id={id} />, {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false
    })
}

export const PromptClose = ({ id }: { id: number }) => {
    const qc = useQueryClient()

    const { isLoading, mutate } = useMutation(
        close,
        {
            onSuccess() {
                toast.dismiss()
                qc.invalidateQueries(['lists'])
                toast.success('Lista została zamknięta')
            },
            onError() {
                toast.error('Wystąpił błąd')
            },
        }
    )
    return (
        <>
            <div>Potwierdzam zgodność grafików ze stanem faktycznym za miesiąc poprzedni oraz akceptuję wysyłkę SMS do UCP.</div>
            <div className='d-flex justify-content-between mt-2'>
                <Button variant="danger" disabled={isLoading} onClick={() => toast.dismiss()}>Nie</Button>
                <Button variant="success" disabled={isLoading} onClick={() => mutate(id)}>Tak</Button>
            </div>
        </>
    )
}

export default PromptClose