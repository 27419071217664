import Spinner from 'components/Spinner'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { activation, fetchLocations } from 'api/locations'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { FaRegEdit } from 'react-icons/fa'
import Icon from 'components/Icon'
import Activation from 'components/Activation'

export const Locations = () => {
    const { isLoading, data } = useQuery('locations', () =>
        fetchLocations(false, true)
    )
    const qc = useQueryClient()

    const { mutate } = useMutation(activation, {
        onSuccess: () => qc.invalidateQueries('locations'),
    })

    if (isLoading) return <Spinner />

    return (
        <>
            <Link
                to="/configuration/locations/add"
                className="btn btn-primary mb-2"
            >
                Dodaj
            </Link>
            <Table>
                <thead className="bg-white sticky-top">
                    <tr>
                        <th>Nazwa klienta</th>
                        <th>Nazwa</th>
                        <th>Miejscowość</th>
                        <th>Kod pocztowy</th>
                        <th>Adres</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((l) => (
                        <tr key={l.id}>
                            <td>{l.customer_name}</td>
                            <td>{l.name}</td>
                            <td>{l.city}</td>
                            <td>{l.post_code}</td>
                            <td>{l.address}</td>
                            <td className="text-nowrap">
                                <Link to={`/configuration/locations/${l.id}`}>
                                    <Icon name={FaRegEdit} />
                                </Link>
                                <Activation
                                    id={l.id!}
                                    isActive={l.is_active!}
                                    mutate={mutate}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default Locations
