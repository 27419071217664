import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthContext } from './AuthProvider'
import Layout from 'components/Layout'
import { Calendar, Lists, Login, Schedules } from 'screens'
import {
    Configuration,
    LocationUser,
    StationShift,
} from 'screens/Configuration'
import {
    AddLocation,
    EditLocation,
    Locations,
} from 'screens/Configuration/Locations'
import { AddShift, EditShift, Shifts } from 'screens/Configuration/Shifts'
import {
    AddStation,
    EditStation,
    Stations,
} from 'screens/Configuration/Stations'
import {
    canAccessConfiguration,
    canAccessLists,
    canAccessSchedules,
} from 'permissions'
import {
    AddSalarySupplement,
    EditUser,
    TemporaryGroups,
    Users,
} from 'screens/Configuration/Users'

const Router = () => {
    const { accessToken } = useContext(AuthContext)

    const root = canAccessSchedules() ? <Schedules /> : <Calendar />
    const accessList = canAccessLists()
    const accessConfiguration = canAccessConfiguration()

    return (
        <BrowserRouter>
            <Routes>
                {accessToken ? (
                    <Route path="" element={<Layout />}>
                        <Route path="" element={root} />
                        <Route path="calendar" element={<Calendar />} />

                        {accessList && (
                            <Route path="lists" element={<Lists />} />
                        )}

                        {accessConfiguration && (
                            <Route
                                path="configuration"
                                element={<Configuration />}
                            >
                                <Route path="shifts" element={<Shifts />} />
                                <Route
                                    path="shifts/add"
                                    element={<AddShift />}
                                />
                                <Route
                                    path="shifts/:id"
                                    element={<EditShift />}
                                />
                                <Route
                                    path="locations"
                                    element={<Locations />}
                                />
                                <Route
                                    path="locations/add"
                                    element={<AddLocation />}
                                />
                                <Route
                                    path="locations/:id"
                                    element={<EditLocation />}
                                />
                                <Route path="stations" element={<Stations />} />
                                <Route
                                    path="stations/add/:id"
                                    element={<AddStation />}
                                />
                                <Route
                                    path="stations/:id"
                                    element={<EditStation />}
                                />
                                <Route
                                    path="assign-shifts"
                                    element={<StationShift />}
                                />
                                <Route
                                    path="assign-users"
                                    element={<LocationUser />}
                                />
                                <Route path="users" element={<Users />} />
                                <Route
                                    path="users/:id"
                                    element={<EditUser />}
                                />
                                <Route
                                    path="users/temp-groups/:id"
                                    element={<TemporaryGroups />}
                                />
                                <Route
                                    path="users/:id/salary-supplement"
                                    element={<AddSalarySupplement />}
                                />
                            </Route>
                        )}
                    </Route>
                ) : (
                    <Route path="/" element={<Login />} />
                )}
                {/* <Route path="*" element={<Navigate to="/" />} /> */}
            </Routes>
        </BrowserRouter>
    )
}

export default Router
