import { addLocation } from 'api/locations'
import LocationForm from 'components/Configuration/LocationForm'
import { Location } from 'models'
import React from 'react'

const initialValues: Location = {
    name: '',
    customer_name: '',
    city: '',
    post_code: '',
    address: '',
}

export const AddLocation = () => {
    return <LocationForm initialValues={initialValues} mutation={addLocation} />
}

export default AddLocation
