import Spinner from 'components/Spinner'
import dayjs from 'dayjs'
import {
    useFilteredStations,
    useFilteredUsers,
    useSchedules,
} from 'hooks/schedules'
import { Contract } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useEffect } from 'react'
import { makeArray } from 'utils'
import MonthRemaining from './MonthRemaining'
import MonthStationHours from './MonthStationHours'
import StationHours from './StationHours'
import UserHours from './UserHours'
import WorkCell from './WorkCell'

const GridBodyStations = () => {
    const {
        state: {
            year,
            month,
            userId,
            shiftId,
            availableStations,
            availableContract,
        },
    } = useSchedulesContext()

    const days = dayjs(`${year}-${month}-01`).daysInMonth()

    const {
        hoursMonthStation,
        hoursStation,
        hoursUserStation,
        monthRemaining,
        stations,
        users,
        work,
        stationsWeekdayHours,
        isLoading,
        stationShiftTimeType,
    } = useSchedules()

    useEffect(() => {
        hoursMonthStation.refetch()
    }, [userId])

    const filteredStations = useFilteredStations(stations.data!)
    const filteredUsers = useFilteredUsers(users.data!)

    if (isLoading) return <Spinner />

    return (
        <>
            {filteredStations?.map((s) => {
                const disabled =
                    !!shiftId &&
                    !!availableStations &&
                    !availableStations.includes(s.id!)
                const usersStation = filteredUsers.filter(
                    (u) => u.stationId === s.id
                )

                return (
                    <React.Fragment key={s.id}>
                        <div
                            className={`col-1 row-span-n cell station-name ${
                                disabled ? 'disabled' : ''
                            }`}
                            style={{
                                ['--row-span' as string]: usersStation?.length,
                            }}
                        >
                            {s.name}
                        </div>

                        {usersStation?.length === 0 && (
                            <div
                                style={{
                                    gridColumnStart: 2,
                                    gridColumnEnd: -1,
                                }}
                            ></div>
                        )}

                        {usersStation?.map((u) => {
                            const disabledUser =
                                (availableContract !== Contract.All &&
                                    availableContract !== u.contract) ||
                                u.disabled!

                            return (
                                <React.Fragment key={u.id}>
                                    <div
                                        className={`text-nowrap cell grid-center sticky-left ${
                                            disabled ? 'disabled' : ''
                                        }`}
                                    >
                                        {u.lastName} {u.firstName}
                                        <br />
                                        <small className="fw-light">
                                            {u.number} / {u.contract}
                                        </small>
                                    </div>

                                    {makeArray(days).map((_, i) => (
                                        <WorkCell
                                            day={i + 1}
                                            stationId={s.id!}
                                            userId={u.id!}
                                            disabled={disabled || disabledUser}
                                            work={work.data!}
                                            key={i}
                                        />
                                    ))}

                                    <MonthRemaining
                                        remaining={monthRemaining.data!}
                                        userId={u.id!}
                                    />

                                    <UserHours
                                        userId={u.id}
                                        stationId={s.id!}
                                        hours={hoursUserStation.data!}
                                    />
                                </React.Fragment>
                            )
                        })}

                        {/* Bottom row */}

                        <MonthStationHours
                            days={days}
                            recordId={s.id!}
                            hours={hoursMonthStation.data!}
                            requiredHours={stationsWeekdayHours.data[s.id!]}
                            timeTypeShifts={stationShiftTimeType.data!}
                            title="Suma godzin na posterunku w danym dniu (bez urlopów)"
                        />

                        <div className="cell bg-gray-500"></div>

                        <StationHours
                            hours={hoursStation.data!}
                            recordId={s.id!}
                        />
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default GridBodyStations
