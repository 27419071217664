import dayjs from 'dayjs'
import { useSetWorkMutation } from 'hooks/schedules'
import { WorkCache } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React from 'react'
import tinycolor from 'tinycolor2'
import { isToday, makeDate } from 'utils'

type Props = {
    stationId: number
    userId: string
    day: number
    disabled: boolean
    work: WorkCache
}

const WorkCell: React.FC<Props> = ({
    stationId,
    userId,
    day,
    disabled,
    work,
}) => {
    const {
        state: { locationId, year, month, shiftId },
    } = useSchedulesContext()
    const { mutate } = useSetWorkMutation(locationId!, year, month)
    const date = makeDate(year, month, day)
    const key = `${date}:${stationId}:${userId}`
    const boundary = isToday(year, month, day) ? 'day-boundary' : ''
    const weekday = dayjs(date).isoWeekday()
    const weekend = weekday === 6 || weekday === 7

    const onClickCell = (stationId: number, userId: string, day: number) => {
        mutate({
            shift_id: shiftId!,
            station_id: stationId,
            user_id: userId,
            date: `${year}-${month}-${day}`,
        })
    }

    return (
        <div
            className={`cell work ${weekend ? 'weekend' : ''} ${
                disabled ? 'disabled' : ''
            }  ${boundary}`}
            key={day}
            onClick={() => {
                if (!disabled) onClickCell(stationId, userId, day)
            }}
        >
            {work &&
                work[key]?.map((sh) => {
                    let presence = ''
                    if (sh.presence === 1) presence = 'present'
                    else if (sh.presence === 0) presence = 'not-present'

                    return (
                        <div
                            style={{
                                backgroundColor: sh.color,
                                color: tinycolor(sh.color).isDark()
                                    ? 'white'
                                    : 'black',
                            }}
                            key={sh.id}
                            className={`user-select-none ${presence}`}
                        >
                            {sh.name}
                        </div>
                    )
                })}
        </div>
    )
}

export default WorkCell
