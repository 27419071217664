import { AxiosResponse } from 'axios'
import FieldError from 'components/FieldError'
import { useFormik } from 'formik'
import {
    Contract,
    contractNames,
    Errors,
    ShiftPayload,
    ShiftTimeType,
    shiftTimeTypeNames,
    shiftTypeNames,
} from 'models'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
    initialValues: ShiftPayload
    mutation: (data: ShiftPayload) => Promise<AxiosResponse<Errors, any>>
}

const ShiftForm: React.FC<Props> = ({ initialValues, mutation }) => {
    const { isLoading, mutate, error } = useMutation<any, any, ShiftPayload>(
        mutation
    )
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const errors = error?.response.data

    const { handleSubmit, values, setFieldValue, handleChange } =
        useFormik<ShiftPayload>({
            initialValues,
            onSubmit: (values) => {
                mutate(values, {
                    onSuccess: ({ data }: any) => {
                        queryClient.setQueryData(['shift', data.id], data)
                        toast.success('Zmiana została zapisana.')
                        navigate('/configuration/shifts')
                    },
                })
            },
        })

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Nazwa</Form.Label>
                <Form.Control
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    isInvalid={!!errors?.name}
                />
                <FieldError errors={errors} name="name" />
            </Form.Group>
            <Form.Group controlId="code" className="mb-3">
                <Form.Label>Kod</Form.Label>
                <Form.Control
                    type="text"
                    onChange={handleChange}
                    value={values.code}
                    isInvalid={!!errors?.code}
                />
                <FieldError errors={errors} name="code" />
            </Form.Group>
            <Form.Group controlId="type" className="mb-3">
                <Form.Label>Typ</Form.Label>
                <Form.Select
                    onChange={handleChange}
                    value={values.type}
                    isInvalid={!!errors?.type}
                >
                    <option>-</option>
                    {Object.entries(shiftTypeNames).map((e) => (
                        <option value={e[0]} key={e[0]}>
                            {e[1]}
                        </option>
                    ))}
                </Form.Select>
                <FieldError errors={errors} name="type" />
            </Form.Group>
            <Form.Group controlId="color" className="mb-3">
                <Form.Label>Kolor</Form.Label>
                <Form.Control
                    type="color"
                    onChange={handleChange}
                    value={values.color}
                    isInvalid={!!errors?.color}
                    style={{ minWidth: '5rem' }}
                />
                <FieldError errors={errors} name="color" />
            </Form.Group>
            <Form.Group controlId="start" className="mb-3">
                <Form.Label>Początek</Form.Label>
                <Form.Control
                    type="time"
                    onChange={handleChange}
                    value={values.start}
                    isInvalid={!!errors?.start}
                />
                <FieldError errors={errors} name="start" />
            </Form.Group>
            <Form.Group controlId="end" className="mb-3">
                <Form.Label>Koniec</Form.Label>
                <Form.Control
                    type="time"
                    onChange={handleChange}
                    value={values.end}
                    isInvalid={!!errors?.end}
                />
                <FieldError errors={errors} name="end" />
            </Form.Group>
            <Form.Check
                type="checkbox"
                name="count_work_time"
                id="count_work_time"
                onChange={(e) =>
                    setFieldValue('count_work_time', e.target.checked)
                }
                label="Licz do czasu pracy"
                className="mb-3"
                checked={values.count_work_time}
            />
            <div className=" mb-3">
                <Form.Label className="me-4">Rodzaj umowy</Form.Label>
                <Form.Check
                    inline
                    label={contractNames[Contract.All]}
                    name="contract"
                    type="radio"
                    checked={values.contract === Contract.All}
                    id="contract-1"
                    onChange={() => setFieldValue('contract', Contract.All)}
                    value={Contract.All}
                />
                <Form.Check
                    inline
                    label={contractNames[Contract.UoP]}
                    name="contract"
                    type="radio"
                    checked={values.contract === Contract.UoP}
                    id="contract-2"
                    onChange={() => setFieldValue('contract', Contract.UoP)}
                    value={Contract.UoP}
                />
                <Form.Check
                    inline
                    label={contractNames[Contract.UZ]}
                    name="contract"
                    type="radio"
                    checked={values.contract === Contract.UZ}
                    id="contract-3"
                    onChange={() => setFieldValue('contract', Contract.UZ)}
                    value={Contract.UZ}
                />
            </div>
            <div className=" mb-3">
                <Form.Label className="me-4">Czas zmiany</Form.Label>
                <Form.Check
                    inline
                    label={shiftTimeTypeNames[ShiftTimeType.Day]}
                    name="time_type"
                    type="radio"
                    checked={values.time_type === ShiftTimeType.Day}
                    id="time-type-1"
                    onChange={() =>
                        setFieldValue('time_type', ShiftTimeType.Day)
                    }
                    value={ShiftTimeType.Day}
                />
                <Form.Check
                    inline
                    label={shiftTimeTypeNames[ShiftTimeType.Night]}
                    name="time_type"
                    type="radio"
                    checked={values.time_type === ShiftTimeType.Night}
                    id="time-type-2"
                    onChange={() =>
                        setFieldValue('time_type', ShiftTimeType.Night)
                    }
                    value={ShiftTimeType.Night}
                />
            </div>
            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default ShiftForm
