import { AxiosResponse } from 'axios'
import FieldError from 'components/FieldError'
import { useFormik } from 'formik'
import { Errors, Station, StationPayload } from 'models'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
    initialValues: Station
    mutation: (data: StationPayload) => Promise<AxiosResponse<Errors, any>>
    locationId?: number
}

const StationForm: React.FC<Props> = ({
    initialValues,
    mutation,
    locationId,
}) => {
    const { isLoading, mutate, error } = useMutation<any, any, StationPayload>(
        mutation
    )
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const errors = error?.response.data

    const formik = useFormik<Station>({
        initialValues,
        onSubmit: (values) => {
            mutate(
                { station: values, locationId },
                {
                    onSuccess: ({ data }: any) => {
                        queryClient.setQueryData(['station', data.id], data)
                        toast.success('Posterunek został zapisany.')
                        navigate(`/configuration/stations`)
                    },
                }
            )
        },
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Nazwa</Form.Label>
                <Form.Control
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!errors?.name}
                />
                <FieldError errors={errors} name="name" />
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
                <Form.Label>Opis</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    isInvalid={!!errors?.description}
                />
                <FieldError errors={errors} name="description" />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default StationForm
