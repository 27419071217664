import { Day, SchedulesLayout } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useState } from 'react'
import GridBodyStations from './GridBodyStations'
import GridBodyUsers from './GridBodyUsers'
import GridFooter from './GridFooter'
import GridHeader from './GridHeader'

const Grid = () => {
    const [days, setDays] = useState<Day[]>([])
    const {
        state: { layout },
    } = useSchedulesContext()

    return (
        <div
            className="mt-3 schedules-grid"
            style={{ ['--grid-columns' as string]: days.length + 4 }}
        >
            <GridHeader days={days} setDays={setDays} />
            {layout === SchedulesLayout.Stations ? (
                <GridBodyStations />
            ) : (
                <GridBodyUsers />
            )}
            <GridFooter />
        </div>
    )
}

export default Grid
