import { validate } from 'api/validation'
import Spinner from 'components/Spinner'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useState } from 'react'
import { Badge, Button, Offcanvas } from 'react-bootstrap'
import { useQuery } from 'react-query'

const Validation = () => {
    const [show, setShow] = useState(false)
    const {
        state: { year, month, locationId },
    } = useSchedulesContext()
    const { isLoading, data } = useQuery(
        ['validation', year, month, locationId],
        () => validate(year, month, locationId!),
        { enabled: show }
    )

    const handleClose = () => setShow(false)
    const toggleShow = () => setShow((s) => !s)

    return (
        <>
            <Button
                variant="secondary"
                size="lg"
                className="text-white"
                onClick={toggleShow}
                disabled={!locationId}
            >
                Sprawdź
            </Button>
            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                backdrop={true}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Sprawdzenie</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <ul className="list-unstyled">
                            {!data?.length && (
                                <li className="text-success">
                                    Planowanie poprawne
                                </li>
                            )}

                            {data?.map((v, i) => (
                                <li
                                    key={i}
                                    className="d-flex flex-column gap-2"
                                >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <strong>{v.user}</strong>
                                        <Badge bg="secondary">{v.dates}</Badge>
                                    </div>
                                    <p
                                        className={`fw-light ${
                                            v.highlight ? 'text-danger' : ''
                                        }`}
                                    >
                                        {v.message}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Validation
