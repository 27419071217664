import dayjs from 'dayjs'
import { Contract, SchedulesLayout } from 'models'

export interface State {
    month: number
    quarter: number
    year: number
    locationId?: number
    stationId?: number
    userId?: string
    shiftId?: number
    availableStations?: number[]
    availableContract: Contract
    layout: SchedulesLayout
}

export enum Actions {
    SetMonth = 'set-month',
    SelectNextQuarter = 'select-next-quarter',
    SelectPrevQuarter = 'select-prev-quarter',
    SetLocation = 'set-location',
    SetStation = 'set-station',
    SetUser = 'set-user',
    SetShift = 'set-shift',
    SetAvailableStations = 'set-available-stations',
    SetAvailableContract = 'set-available-contract',
    SetLayout = 'set-layout',
}

export type Action =
    | { type: Actions.SetMonth; month: number }
    | { type: Actions.SelectNextQuarter }
    | { type: Actions.SelectPrevQuarter }
    | { type: Actions.SetLocation; locationId?: number }
    | { type: Actions.SetStation; stationId?: number }
    | { type: Actions.SetUser; userId?: string }
    | { type: Actions.SetShift; shiftId?: number }
    | { type: Actions.SetAvailableStations; availableStations?: number[] }
    | { type: Actions.SetAvailableContract; contract: Contract }
    | { type: Actions.SetLayout; layout: SchedulesLayout }

export const reducer = (state: State, action: Action): State => {
    const selectQuarter = (dir: number) => {
        let day = dayjs(new Date(state.year, state.month - 1, 1)).startOf(
            'quarter'
        )
        if (dir > 0) {
            day = day.add(1, 'quarter')
        } else {
            day = day.subtract(1, 'quarter')
        }

        return {
            ...state,
            quarter: day.quarter(),
            year: day.year(),
            month: day.month() + 1,
        }
    }

    switch (action.type) {
        case Actions.SetMonth:
            return {
                ...state,
                month: action.month,
            }

        case Actions.SelectNextQuarter:
            return selectQuarter(+1)

        case Actions.SelectPrevQuarter:
            return selectQuarter(-1)

        case Actions.SetLocation:
            return {
                ...state,
                locationId: action.locationId,
                stationId: 0,
                userId: undefined,
                shiftId: undefined,
                availableStations: undefined,
            }

        case Actions.SetStation:
            return {
                ...state,
                stationId: action.stationId,
                shiftId: undefined,
            }

        case Actions.SetUser:
            return {
                ...state,
                userId: action.userId,
            }

        case Actions.SetShift: {
            const shiftId =
                state.shiftId !== action.shiftId ? action.shiftId : undefined
            return {
                ...state,
                availableStations: undefined,
                shiftId,
            }
        }

        case Actions.SetAvailableStations:
            return {
                ...state,
                availableStations: action.availableStations,
            }

        case Actions.SetAvailableContract:
            return {
                ...state,
                availableContract: state.shiftId
                    ? action.contract
                    : Contract.All,
            }

        case Actions.SetLayout:
            return {
                ...state,
                layout: action.layout,
            }

        default:
            return state
    }
}
