import { SchedulesLayout } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Actions } from 'reducers/schedulesReducer'

const LayoutBar = () => {
    const {
        state: { layout },
        dispatch,
    } = useSchedulesContext()

    return (
        <div>
            <Form.Check
                type="radio"
                label="Użytkownicy"
                value={SchedulesLayout.Users}
                id="layout-1"
                name="layout"
                checked={layout === SchedulesLayout.Users}
                onChange={() =>
                    dispatch({
                        type: Actions.SetLayout,
                        layout: SchedulesLayout.Users,
                    })
                }
            />
            <Form.Check
                type="radio"
                label="Posterunki"
                value={SchedulesLayout.Stations}
                id="layout-2"
                name="layout"
                checked={layout === SchedulesLayout.Stations}
                onChange={() =>
                    dispatch({
                        type: Actions.SetLayout,
                        layout: SchedulesLayout.Stations,
                    })
                }
            />
        </div>
    )
}

export default LayoutBar
