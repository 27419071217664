import React from 'react'
import { Col, Nav, Row } from 'react-bootstrap'
import { Link, Outlet, useLocation } from 'react-router-dom'

export const Configuration = () => {
    const { pathname } = useLocation()

    return (
        <Row>
            <Col className="" sm={12} md={2}>
                <Nav
                    variant="pills"
                    id="sub-nav"
                    className=" gap-2 mb-3 flex-sm-row flex-md-column"
                >
                    <Nav.Link
                        as={Link}
                        to="/configuration/shifts"
                        active={pathname.includes('/configuration/shifts')}
                    >
                        Zmiany
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/configuration/locations"
                        active={pathname.includes('/locations')}
                    >
                        Obiekty
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/configuration/stations"
                        active={pathname.includes('/configuration/stations')}
                    >
                        Posterunki
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/configuration/assign-shifts"
                        active={pathname.includes(
                            '/configuration/assign-shifts'
                        )}
                    >
                        Przypisanie zmian
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/configuration/assign-users"
                        active={pathname.includes(
                            '/configuration/assign-users'
                        )}
                    >
                        Przypisanie użytkowników
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/configuration/users"
                        active={pathname.includes('/configuration/users')}
                    >
                        Użytkownicy
                    </Nav.Link>
                </Nav>
            </Col>
            <Col className="" sm={12} md={10}>
                <Outlet />
            </Col>
        </Row>
    )
}

export default Configuration
