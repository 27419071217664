import React from 'react'
import { useQuery } from 'react-query'
import { fetchShifts } from 'api/shifts'
import Spinner from 'components/Spinner'
import { Table } from 'react-bootstrap'
import { shiftTimeTypeNames, shiftTypeNames } from 'models'
import { Link } from 'react-router-dom'
import { FaRegEdit } from 'react-icons/fa'
import Icon from 'components/Icon'

export const Shifts = () => {
    const { isLoading, data } = useQuery('shifts', fetchShifts)

    if (isLoading) return <Spinner />

    return (
        <>
            <Link
                to="/configuration/shifts/add"
                className="btn btn-primary mb-2"
            >
                Dodaj
            </Link>
            <Table>
                <thead className="bg-white sticky-top">
                    <tr>
                        <th>Typ</th>
                        <th>Nazwa</th>
                        <th>Kod</th>
                        <th>Rodzaj</th>
                        <th>Początek</th>
                        <th>Koniec</th>
                        <th>Godzin</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((s) => (
                        <tr key={s.id}>
                            <td>{shiftTypeNames[s.type]}</td>
                            <td>{s.name}</td>
                            <td>{s.code}</td>
                            <td>{shiftTimeTypeNames[s.time_type]}</td>
                            <td>{s.start}</td>
                            <td>{s.end}</td>
                            <td>{s.hours}</td>
                            <td>
                                <Link to={`/configuration/shifts/${s.id}`}>
                                    <Icon name={FaRegEdit} />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default Shifts
