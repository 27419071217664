import { useFormik } from 'formik'
import { groupNames } from 'models/groups'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FieldError from 'components/FieldError'
import { TemporaryGroup, TemporaryGroupPayload } from 'models/temporaryGroups'
import { createGroup } from 'api/temporaryGroups'

const initialValues: TemporaryGroup = {}

const AddForm: React.FC = () => {
    const id = useParams().id!
    const queryClient = useQueryClient()
    const { isLoading, mutate, error } = useMutation<
        any,
        any,
        TemporaryGroupPayload
    >(createGroup)
    const errors = error?.response.data
    const formik = useFormik<TemporaryGroup>({
        initialValues,
        onSubmit: (values) => {
            mutate(
                { ...values, userId: id },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['temp-groups', id])
                        toast.success('Uprawnienia zostały zapisane.')
                    },
                }
            )
        },
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="date" className="mb-3">
                <Form.Label>Data</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Data"
                    onChange={formik.handleChange}
                    value={formik.values.date}
                    isInvalid={!!errors?.date}
                />
                <FieldError errors={errors} name="date" />
            </Form.Group>

            <Form.Group controlId="groupId" className="mb-3">
                <Form.Label>Grupa</Form.Label>
                <Form.Select
                    onChange={formik.handleChange}
                    value={formik.values.groupId}
                    isInvalid={!!errors?.groupId}
                >
                    <option value="">-</option>
                    {Object.entries(groupNames).map((g) => (
                        <option value={g[0]} key={g[0]}>
                            {g[1]}
                        </option>
                    ))}
                </Form.Select>
                <FieldError errors={errors} name="groupId" />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default AddForm
