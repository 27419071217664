import { StationShiftTimeTypeCount, StatsPayload } from 'models/stats'
import api from './api'

export const fetchStationShiftTimeTypeCount = async (data: StatsPayload) => {
    const resp = await api.get<StationShiftTimeTypeCount[]>(
        `/stats/station-shift-time-types/${data.locationId}/y-${data.year},m-${data.month}`
    )

    return resp.data
}
