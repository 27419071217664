import { Errors, Shift, ShiftAssignment } from 'models'
import api from './api'

export const fetchShiftAssignments = async (id: number): Promise<number[]> => {
    const resp = await api.get(`/stations/${id}/shifts`)
    const data = resp.data

    return data.map((d: any) => d.id)
}

export const fetchShiftAssignmentsDetailed = async (
    id: number
): Promise<Shift[]> => {
    const resp = await api.get(`/stations/${id}/shifts`)

    return resp.data
}

export const setAssignment = async (data: ShiftAssignment) => {
    const url = `/stations/${data.stationId}/shifts`
    const payload = { shift_id: data.shiftId }

    return data.checked
        ? await api.post<Errors>(url, payload)
        : await api.delete<Errors>(url, { data: payload })
}
