import React from 'react'

type Props = {
    userId: string
    stationId: number
    hours: { [key: string]: number }
}

const UserHours: React.FC<Props> = ({ userId, stationId, hours }) => {
    const key = `${userId}:${stationId}`

    return (
        <div className="cell grid-center bg-gray-500 fw-bolder">
            {hours[key] ?? '-'}
        </div>
    )
}

export default UserHours
