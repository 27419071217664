import { Work as WorkModel } from 'models/calendar'
import { canConfirmPresence } from 'permissions'
import { formatHourMinutes } from 'utils'
import PresenceActions from './PresenceActions'

interface Props {
    work: WorkModel
}

const Work: React.FC<Props> = ({ work }) => {
    return (
        <div className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between">
                <div>
                    <strong>{work.date}</strong> od{' '}
                    <strong>{formatHourMinutes(work.start)}</strong> do{' '}
                    <strong>{formatHourMinutes(work.end)}</strong>
                </div>
                <div>{work.shift_name}</div>
            </div>
            <div className="d-flex justify-content-between">
                <div>
                    {work.user.firstName} {work.user.lastName}
                </div>
                {work.user.number}
            </div>
            {canConfirmPresence() && (
                <PresenceActions
                    date={work.date}
                    shiftId={work.shift_id}
                    stationId={work.station_id}
                    userId={work.user.id}
                    presence={work.presence === null ? null : !!work.presence}
                />
            )}
        </div>
    )
}

export default Work
