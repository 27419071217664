import { addStation } from 'api/stations'
import StationForm from 'components/Configuration/StationForm'
import { Station } from 'models'
import React from 'react'
import { useParams } from 'react-router-dom'

const initialValues: Station = {
    name: '',
    description: '',
}

export const AddStation = () => {
    const params = useParams()
    const locationId = parseInt(params.id!)

    return (
        <StationForm
            initialValues={initialValues}
            mutation={addStation}
            locationId={locationId}
        />
    )
}

export default AddStation
