import React from 'react'
import { Station } from 'models'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaRegEdit } from 'react-icons/fa'
import Icon from 'components/Icon'
import { useMutation, useQueryClient } from 'react-query'
import { activation } from 'api/stations'
import Activation from 'components/Activation'

type Props = {
    stations: Station[]
}

const StationsList: React.FC<Props> = ({ stations }) => {
    const qc = useQueryClient()

    const { mutate } = useMutation(activation, {
        onSuccess: () => qc.invalidateQueries('locationsWithStations'),
    })

    return (
        <ListGroup variant="flush">
            {stations.map((s) => (
                <ListGroupItem
                    key={s.id}
                    className="d-flex justify-content-between align-items-center"
                >
                    {s.name}
                    <div>
                        <Link to={`/configuration/stations/${s.id}`}>
                            <Icon name={FaRegEdit} />
                        </Link>

                        <Activation
                            id={s.id!}
                            isActive={s.is_active!}
                            mutate={mutate}
                        />
                    </div>
                </ListGroupItem>
            ))}
        </ListGroup>
    )
}

export default StationsList
