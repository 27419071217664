import { ValidationResult } from 'models'
import api from './api'

export const validate = async (
    year: number,
    month: number,
    locationId: number
) => {
    const resp = await api.post<ValidationResult[]>(
        `/validation/${locationId}/y-${year},m-${month}`
    )

    return resp.data
}
