import { fetchShifts } from 'api/locationShifts'
import Spinner from 'components/Spinner'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { Actions } from 'reducers/schedulesReducer'
import tinycolor from 'tinycolor2'

const ShiftPicker = () => {
    const { state, dispatch } = useSchedulesContext()
    const { isLoading, data } = useQuery(
        ['shiftsWithStationsForLocation', state.locationId],
        () => fetchShifts(state.locationId!)
    )

    useEffect(() => {
        const availableStations: number[] = data?.reduce((acc: any, val) => {
            return val.id === state.shiftId
                ? [...acc, ...val.stations!.map((s) => s.id)]
                : acc
        }, [])
        dispatch({ type: Actions.SetAvailableStations, availableStations })
    }, [state.shiftId])

    if (isLoading) return <Spinner />

    return (
        <div className="d-flex flex-wrap justify-content-center gap-3 mt-3">
            {data?.map((s) => (
                <React.Fragment key={s.id}>
                    {s.stations!.length > 0 && (
                        <Button
                            key={s.id}
                            className="shadow-none"
                            style={{
                                borderColor: s.color,
                                backgroundColor: s.color,
                                color: tinycolor(s.color).isDark()
                                    ? 'white'
                                    : 'black',
                                transform:
                                    s.id === state.shiftId ? 'scale(1.2)' : '',
                                transition: 'ease-in 0.2s',
                            }}
                            onClick={() => {
                                dispatch({
                                    type: Actions.SetShift,
                                    shiftId: s.id,
                                })
                                dispatch({
                                    type: Actions.SetAvailableContract,
                                    contract: s.contract,
                                })
                            }}
                        >
                            {s.name}
                            <br />
                            <small className="fw-lighter">
                                {s.start} - {s.end}
                            </small>
                        </Button>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}

export default ShiftPicker
