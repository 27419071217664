import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { Errors, LoginPayload } from "../models";
import jwt_decode from "jwt-decode";
import { User } from "models/users";

interface Props {
    errors: null | Errors;
    accessToken: null | string;
    profile: null | User;
    logout: () => void;
    clearErrors: () => void;
    login: (data: LoginPayload) => void;
}

export const AuthContext = createContext({} as Props);

export const AuthProvider: React.FC = ({ children }) => {
    const [errors, setErrors] = useState<Errors | null>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [profile, setProfile] = useState<User | null>(null);

    useEffect(() => {
        setToken(localStorage.getItem("eco.token") || "");
        setProfile(JSON.parse(localStorage.getItem("eco.user") || "{}"));
    }, []);

    const setToken = (token: string) => {
        setAccessToken(token);
        localStorage.setItem("eco.token", token);
    };

    const setUser = (user: User) => {
        localStorage.setItem("eco.user", JSON.stringify(user));
        setProfile(user);
    };

    return (
        <AuthContext.Provider
            value={{
                accessToken,
                profile,
                logout: () => {
                    setAccessToken(null);
                    setProfile(null);
                    localStorage.removeItem("pgnig.token");
                    localStorage.removeItem("pgnig.user");
                },
                errors,
                clearErrors: () => setErrors(null),
                login: async (data: LoginPayload) => {
                    try {
                        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data);

                        const token = resp.data.access_token;
                        const decoded = jwt_decode<{ user: User }>(token);

                        setToken(token);
                        setUser(decoded.user);
                    } catch (err: any) {
                        if (err.response.status === 401) {
                            setErrors({
                                login: ["Niepoprawny login lub hasło"],
                            });
                        } else {
                            setErrors(err.response.data);
                        }
                    }
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
